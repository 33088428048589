import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const splitClasses = classNames(
  "hero-split-wrap"
  // invertMobile && "invert-mobile",
  // invertDesktop && "invert-desktop",
  // alignTop && "align-top"
);

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className={splitClasses}>
              <div className="hero-split-item">
                <div
                  className="hero-split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".hero-split-item"
                >
                  <div
                    className="hero-title-outer mt-0 mb-16 reveal-from-bottom"
                    data-reveal-delay="200"
                  >
                    <div className="hero-title-inner">
                      <h1
                        className="mt-0 mb-16 reveal-from-bottom"
                        data-reveal-delay="200"
                      >
                        <Typist
                          startDelay={500}
                          avgTypingDelay={30}
                          stdTypingDelay={1}
                        >
                          Build self
                          <br />
                          confidence.
                          <Typist.Delay ms={1750} />
                          <br />
                          <span className="text-color-hero">
                            Authentically.
                          </span>
                        </Typist>
                      </h1>
                    </div>
                    <div className="hero-title-inner visibility-hidden">
                      <h1 className="mt-0 mb-16 reveal-from-bottom">
                        Build self
                        <br />
                        confidence.
                        <br />
                        Authentically.|
                      </h1>
                    </div>
                  </div>
                  <div className="container-xs">
                    <p
                      className="m-0 mb-32 reveal-from-bottom"
                      data-reveal-delay="400"
                    >
                      Write your own <u>personalized</u> affirmations.
                      <br />
                      Repeat them every day and track your progress.
                    </p>
                    <div
                      className="reveal-from-bottom"
                      data-reveal-delay="600"
                      data-reveal-offset="100"
                    >
                      <ButtonGroup>
                        <Button
                          tag="a"
                          color="primary"
                          target="_blank"
                          wideMobile
                          href="https://app.repetition.works"
                        >
                          Go to App
                        </Button>
                        {/* <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button> */}
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    "hero-split-item-image center-content-mobile reveal-from-bottom",
                    "hero-split-item-image-fill"
                  )}
                  data-reveal-container=".hero-split-item"
                >
                  {/* <Image
                    src={require("./../../assets/images/hero-img.jpg")}
                    alt="Hero split"
                    width={300}
                    // width={528}
                    // height={396}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.jpg")}
                alt="Hero"
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
