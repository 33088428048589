import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section mt-32",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  // const sectionHeader = {
  //   title: "Focus on yourself naturally",
  //   paragraph:
  //     "You don't need to read a dozen self-help books. You just need 10 minutes a day.",
  // };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div
            className="center-content section-header reveal-from-bottom"
            data-reveal-delay="400"
          >
            <div className="container-xs">
              <h2 className="mt-0 mb-16">It's really simple</h2>
              <p className="m-0">When you repeat thoughts, 2 things happen:</p>
              <div className="mt-24 mb-24">
                <img className="mb-8" alt="Head muscle" src={require("./../../assets/images/head-muscle.svg")} />
                <div >You make them <u>stronger</u>.</div>
              </div>
              {/* <br /> */}
              <div className="mt-24 mb-24">
                <img className="mb-8" alt="Head muscle" src={require("./../../assets/images/head-winding.svg")} />
                <div>You <u>prime yourself</u> psychologically.</div>
              </div>


              <p>
                Do this for long enough, and you can change how you feel, think
                and behave.
              </p>
            </div>
          </div>

          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Plain and simple
                </div>
                <h3 className="mt-0 mb-12">Here is how it works</h3>
                <div className="m-0">
                  <p>
                    Our app guides you to write your own set of affirmations,
                    fine-tuned for your needs.
                  </p>
                  <p>
                    You repeat the affirmations daily for the next 3 weeks,
                    maximum 10 minutes per day.
                  </p>
                  <p>
                    Our app tracks your progress and guides you to update the
                    affirmations to match your level of confidence.
                  </p>
                  {/* <p>
                    At the end of 3 weeks, you decide whether to continue
                    working on the same issue, or move on to a new one.
                  </p> */}
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/undraw_Online_calendar_re_wk3t.svg")}
                  alt="Features split 04"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Motivation doesn't have to cringe
                </div>
                <h3 className="mt-0 mb-12">Feel authentic from day one</h3>
                <p className="m-0">
                  We let you start with what feels right for you, right now.
                  This helps you <u>own</u> the affirmations.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/undraw_among_nature_p1xb.svg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Grow at your own pace
                </div>
                <h3 className="mt-0 mb-12">Stay true to yourself</h3>
                <div className="m-0">
                  <p>
                    When you go to the gym, you take care not to push yourself
                    too hard.
                  </p>
                  <p>
                    Similarly, if saying something positive doesn't feel right,
                    we help you make it more modest.
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/undraw_personal_trainer_ote3.svg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Start the habit
                </div>
                <h3 className="mt-0 mb-12">
                  Repetition <u>Works</u>
                </h3>
                <p className="m-0">
                  The brain has a capacity to rewire itself, even through old
                  age. Negative sentiments can be made positive given enough
                  time, through repetition.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/undraw_things_to_say_ewwb.svg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
