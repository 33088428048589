import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
// import ReactGA from 'react-ga';
import GA4React from "ga-4-react";
import ReactPixel from 'react-facebook-pixel';

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";

import tawkTo from "tawkto-react";

const tawkToPropertyId = "608813245eb20e09cf371080";
const tawkToKey = "1f49olhod";

// Google Analytics 4
const ga4react = new GA4React(
  process.env.REACT_APP_GA_CODE,
  {
    /* ga custom config, optional */
  },
  [
    /* additional code, optional */
  ],
  5000 /* timeout, optional, defaults is 5000 */,
  {} /* { nonce: ['first-script-is-async','second-script'] } */
);
ga4react.initialize().then(
  (ga4) => {
    ga4.pageview("path");
    ga4.gtag("event", "pageview", "path"); // or your custom gtag event
  },
  (err) => {
    console.error(err);
  }
);

// // Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };



const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // trackPage(page);
    tawkTo(tawkToPropertyId, tawkToKey);
    // Facebook Pixel
    // ReactPixel.init(process.env.FACEBOOK_PIXEL_ID, {}, {});
    ReactPixel.init('292591995745636', {}, {});
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
        </Switch>
      )}
    />
  );
};

export default App;
