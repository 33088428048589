import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    // "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div
            className="reveal-from-bottom center-content section-header"
            data-reveal-delay="400"
          >
            <div className="container-xs">
              <h2 className="mt-0 mb-16">We get you.</h2>
              <p className="m-0">
                The self-help industry is good at helping people be more
                productive—but not so good at helping them{" "}
                <b>love and accept themselves</b>. These problems are hard to solve:
              </p>
              {/* <p className="m-0">
                What if there were a better alternative to{" "}
                <em>&lsquo;fake it till you make it&rsquo;</em>&nbsp;?
              </p> */}
            </div>
          </div>

          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-01.svg")}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Impostor syndrome</h4>
                  {/* <p className="m-0 text-sm">
                    Feeling like a fraud?
                    <br />
                    &lsquo;Faking it&rsquo; will most likely not help.
                  </p> */}
                  {/* <br /> */}
                  {/* We can show you how to extinguish crippling self-doubt. */}
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-02.svg")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Social anxiety</h4>
                  {/* <p className="m-0 text-sm">
                    Do you feel like everybody is judging you?
                  </p> */}
                  {/* It could be <em>you</em> who are judging yourself, and */}
                  {/* not the others. */}
                  {/* <br /> */}
                  {/* If that's the case, we can help you accept yourself */}
                  {/* unconditionally. */}
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Helplessness</h4>
                  {/* <p className="m-0 text-sm">
                    Are you stuck in a loop, and nothing you do seems to improve
                    the situation?
                    </p> */}
                  {/* <br />
                    If this is due to your beliefs about yourself, we can help
                    you break out of it. */}
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-04.svg")}
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Codependency</h4>
                  {/* <p className="m-0 text-sm">
                    Do you keep pushing the wrong buttons and pulling people
                    down?
                    </p> */}
                  {/* <br />
                    We can show you how to generate self-esteem by yourself,
                    without seeking it in others. */}
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-05.svg")}
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Excessive negativity</h4>
                  {/* <p className="m-0 text-sm">
                    "You're such a pessimist."
                    <br />
                    "No, I'm a realist."
                    <br />
                    Does this sound familiar?
                  </p> */}
                  {/* If you're tired of hearing it, we can help. */}
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-06.svg")}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Feelings of not being good enough
                  </h4>
                  {/* <p className="m-0 text-sm">
                    Is your inner critic too harsh?
                    </p> */}
                  {/* We all fall down at some point in life. You could be beating */}
                  {/* yourself up, instead of getting back up. */}
                  {/* <br />
                    We can show you how to improve without being too hard on
                    yourself. */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
