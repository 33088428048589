import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  // const sectionHeader = {
  //   title: "We can help you!",
  //   paragraph: "Here is what some of our customers have to say about us:",
  // };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div
            className="center-content section-header reveal-from-bottom"
            data-reveal-delay="400"
          >
            <div className="container-xs">
              <h2 className="mt-0 mb-16">Our users love us!</h2>
              <p className="m-0">
                Here is what they have to say about us:
              </p>
            </div>
          </div>
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    When I practice, I feel as if a channel opens in my mind.
                    That part of my mind is always clear and goal-oriented, and
                    no thought that leads to doubt can survive there. It's as if
                    I have swept the cobwebs out of my head.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Celina—strengthened the belief{" "}
                    <em>"I can be good at math if I want to."</em>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    I am definitely closer to my goal. Every now and then, I
                    realize that my inner voice is telling me the things I have
                    repeated during my practice. My beliefs have shifted which I
                    think affects my decisions to a certain degree.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Tom—strengthened the belief{" "}
                    <em>
                      "I am an organized person and I can keep up with my
                      plans."
                    </em>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    I had beliefs about not being socially capable from being
                    bullied as a teenager. After 3 weeks, I feel more relaxed
                    and confident around people. The old beliefs are still
                    there, but there is now a stronger voice inside me that
                    speaks more positively.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Oliver—strengthened the belief{" "}
                    <em>"I am socially capable."</em>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
